import axios from "axios";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

function Footer() {

  const [footerDataStrapi, setFooterDataStrapi] = useState([]);

  useEffect(() => {

    axios.get(`${process.env.REACT_APP_STRAPI_BASE_URL}/api/footer?populate=deep`)
    .then(res => {

      const footerDatas = {
        id: res.data.data.id,
        couleurFond: res.data.data.attributes.couleur_fond,
        couleurTxt: res.data.data.attributes.couleur_txt,
        texte: res.data.data.attributes.texte,
        RSs: res.data.data.attributes.reseaux_sociaux.map(RS => ({
          id: RS.id,
          image: process.env.REACT_APP_STRAPI_BASE_URL + RS.image.data.attributes.url,
          nom: RS.nom,
          lien: RS.lien,
        })),
      }
      
      setFooterDataStrapi(footerDatas);

    })
    .catch(err => {
      console.log('error', err);
    });

  }, []);


  return (
    <footer id="footer" className="bg-tertiary-200 pt-10 w-full" style={{backgroundColor: footerDataStrapi.couleurFond}}>
      <div className="flex flex-row justify-center">
        {footerDataStrapi.RSs && (
          footerDataStrapi.RSs.map( rs => (
            <NavLink key={rs.id} to={rs.lien} target="_blank" className="w-16">
              <img src={rs.image} alt="logo Determinant"/>
            </NavLink>
          ))
        )}
      </div>
      <p className="text-center pb-10 font-secondary text-xs sm:text-sm lg:text-xl"  style={{color: footerDataStrapi.couleurTxt}} dangerouslySetInnerHTML={{ __html: footerDataStrapi.texte }}></p>
    </footer>
  );
}
export default Footer;