import { useEffect, useState } from 'react';
import { Parallax } from 'react-parallax';

function HomeHeaderBackground( {infos_header} ) {

    const [isMobile, setIsMobile] = useState(false);
    const [loading, setLoading] = useState(true);
    const imgClassName = isMobile ? 'mobile-class' : 'desktop-class';
    const updateScreen = () => {
        setIsMobile(window.innerWidth <= 990);
    }

    useEffect(() => {
        

        if (infos_header && Object.keys(infos_header).length > 0) {
            setLoading(false);
        }

        updateScreen();
        window.addEventListener('resize', updateScreen);
        return () => window.removeEventListener('resize', updateScreen);
        

    }, [infos_header]);


    return (
        <>
            {loading ? (
                <div style={{ width: '100vw', height: '100vh', backgroundColor: '#F4F4F4', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                
                </div>
            ) : (
                <div id="headerVisuel" className="relative h-screen" 
                    style={{
                        backgroundImage: infos_header.imgDesktop 
                        ? `url(${process.env.REACT_APP_STRAPI_BASE_URL}${infos_header.imgDesktop.data.attributes.url})`
                        : undefined
                    }}>
                    {imgClassName === 'mobile-class' ?
                        <img src={process.env.REACT_APP_STRAPI_BASE_URL + infos_header.imgMobile.data.attributes.url} alt="header" className="w-full h-full object-cover" />
                        : 
                        <>
                            {/* Affichage des images */}
                            {infos_header.images && infos_header.images.length > 0 && ( 
                                         
                                infos_header.images.map( imageParallax => (
                                    <Parallax  
                                        key={imageParallax.id} 
                                        bgImage={process.env.REACT_APP_STRAPI_BASE_URL + imageParallax.image.data.attributes.url}  
                                        strength={Number(imageParallax.vitesse_image)} 
                                        className={`img-parallax left-0 w-full h-full img-bottom z-${imageParallax.plan_image}`}
                                    />
                                ))
                                
                            )}
                            {/* Affichage du texte */}
                            <div className='absolute top-1/2 right-0 w-1/2 mt-[-200px] z-50'>
                                {/* Affichage du texte */}
                                {infos_header.texte && (
                                    <div data-aos="fade-left" data-aos-duration="500">
                                        <Parallax
                                            strength={`${infos_header.texte.vitesse_txt}`} 
                                            className={`z-${infos_header.texte.plan_txt}`}>
                                            <h1 
                                                className="sm:text-2xl 3xl:text-4xl text-white text-left font-primary pr-24" style={{
                                                    color: infos_header.texte.couleur,
                                                    lineHeight: '2' 
                                                }} 
                                                dangerouslySetInnerHTML={{ __html: infos_header.texte.introduction }}>
                                            </h1>
                                        </Parallax>
                                    </div>
                                )}
                                {/* Affichage du bouton */}
                                {infos_header.bouton && (
                                    <div data-aos="fade-left" data-aos-duration="1000">
                                        <Parallax    
                                            strength={`${infos_header.bouton.vitesse_btn}`} 
                                            className={`z-${infos_header.bouton.plan_btn}`}>
                                            <button 
                                                style={{ 
                                                    backgroundColor: infos_header.bouton.couleur_fond,
                                                    color: infos_header.bouton.couleur_texte
                                                }}
                                                type='button' 
                                                className='sm:text-sm 3xl:text-xl font-primary p-10 mt-10 rounded-full uppercase font-bold'
                                                onClick={() => {
                                                    const devisElement = document.getElementById('entites');
                                                    if (devisElement) {
                                                        devisElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                                                    }
                                                }}>
                                                {infos_header.bouton.texte}
                                            </button>
                                        </Parallax>
                                    </div>
                                )}
                            </div>
                        </>
                    }
                    
                </div>
            )}
        </>

    );
}

export default HomeHeaderBackground;