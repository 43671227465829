import Carrousel from "../components/Carousel";
import Partenaires from "../components/Partenaires";
import Entites from "../components/Entites";
import Equipes from "../components/Equipes";
import HomeHeaderBackground from "../components/HomeHeaderBackground";
import Devis from "./devis";
import { useEffect, useState } from "react";
import axios from "axios";

function Home() {

  const [parallaxDataStrapi, setParallaxDataStrapi] = useState([]);
  const [entitesDataStrapi, setEntitesDataStrapi] = useState([]);
  const [carouselDataStrapi, setCarouselDataStrapi] = useState([]);
  const [paetenairesDataStrapi, setPartenairesDataStrapi] = useState([]);    
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {

      axios.get(`${process.env.REACT_APP_STRAPI_BASE_URL}/api/page-homes?populate=deep`)
      .then(res => {
        let fullRes = res.data.data[0].attributes;

        setParallaxDataStrapi(fullRes.header);
        setEntitesDataStrapi(fullRes.entites);
        setCarouselDataStrapi(fullRes.carousel);
        setPartenairesDataStrapi(fullRes.partenaires);

        setLoading(false);

      })
      .catch(err => {
        console.log('error', err);
      });

  }, []);

  return (

    <>   
      {loading ? (
        <div className="w-screen h-screen bg-tertiary-100 items-center justify-center"></div>
      ) : (
        <>
          {parallaxDataStrapi && (
            <HomeHeaderBackground infos_header={parallaxDataStrapi}/>
          )}
          {entitesDataStrapi && (
            <Entites infos_entites={entitesDataStrapi}/>
          )}
          <div className="pb-32"></div>
          {carouselDataStrapi && (
            <Carrousel infos_carousel={carouselDataStrapi}/>
          )}
          <div className="pb-32"></div>
          <Equipes/>
          <div className="pb-32"></div>
          {paetenairesDataStrapi && (
            <Partenaires infos_partenaires={paetenairesDataStrapi}/>
          )}
          <div className="pb-24"></div>
          
        </>
      )}
    </>
            

  );
}
export default Home;