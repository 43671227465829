import { useEffect, useState } from "react";
import MetiersBlocs from "../components/MetiersBlocs";
import axios from "axios";
import Devis from "./devis";

import AOS from 'aos';
import 'aos/dist/aos.css';

function Metier() {

    const [metierIntroDatacStrapi, setMetierIntroDataStrapi] = useState([]);
    const [metier_datas, setMetierDatas] = useState([]);

    useEffect(() => {

      AOS.init({
        duration: 2000,
      });
      
      axios.get(`${process.env.REACT_APP_STRAPI_BASE_URL}/api/informations-generales-metier`)
      .then(res => {

        let metier_intro_datas = {
          id: res.data.data.id,
          titre: res.data.data.attributes.titre,
          sousTitre: res.data.data.attributes.sousTitre,
        }

        setMetierIntroDataStrapi(metier_intro_datas);
        AOS.refresh();
      })
      .catch(err => {
        console.log('error', err);
      });

      axios.get(`${process.env.REACT_APP_STRAPI_BASE_URL}/api/page-metier-articles?populate=*`)
      .then(res => {

          let metier_datas = res.data.data.map((metier) => {
              return {
                  id: metier.id,
                  titre: metier.attributes.titre,
                  img: process.env.REACT_APP_STRAPI_BASE_URL + metier.attributes.imageDesktop.data.attributes.url,
                  imgMobile: process.env.REACT_APP_STRAPI_BASE_URL + metier.attributes.imageMobile.data.attributes.url,
                  description: metier.attributes.description,
                  premiere_colonne: metier.attributes.premiere_colonne ? {
                      titre_colonne: metier.attributes.premiere_colonne.titre_colonne,
                      liste_colonne: metier.attributes.premiere_colonne.liste_colonne,
                  } : null,
                  deuxieme_colonne: metier.attributes.deuxieme_colonne ? {
                      titre_colonne: metier.attributes.deuxieme_colonne.titre_colonne,
                      liste_colonne: metier.attributes.deuxieme_colonne.liste_colonne,
                  } : null,
                  engagements: metier.attributes.engagements ? {
                      titre_colonne: metier.attributes.engagements.titre_colonne,
                      liste_colonne: metier.attributes.engagements.liste_colonne,
                  } : null,
                  conclusion: metier.attributes.conclusion
              }
          });
          
          setMetierDatas(metier_datas);
          AOS.refresh();
      })
      .catch(err => {
          console.log('error', err);
      });
      

    }, []);

    return (
        
      <>
        <div data-aos="fade-up">
            {/* Titre général */}
            <div className="pb-48"></div>
            <div className="flex justify-center w-2/3 m-auto pb-20">
                <h1 className="font-primary text- uppercase text-center text-3xl sm:text-5xl pb-5 border-b-4 border-primary tracking-widest transition-all ease-in-out duration-500">{metierIntroDatacStrapi.titre}</h1>
            </div>

            {/* Sous-titre général */}
            <div className="font-primary text-center text-lg sm:text-xl w-2/3 m-auto metier-sous-titres" dangerouslySetInnerHTML={{ __html: metierIntroDatacStrapi.sousTitre }}>
            </div>
        </div>
        <MetiersBlocs infos_metier={metier_datas}/>
        <div className="pb-24"></div>
        
      </>

    )
}
export default Metier;