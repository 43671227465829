import { NavLink } from "react-router-dom";

import { useEffect, useRef, useState } from "react";
import axios from "axios";
//Librairie d'animation
import { gsap } from "gsap";

function Menu() {

  const [menuDataStrapi, setmenuDataStrapi] = useState([]);
  const logoRef = useRef(null);
  const polygonRefFirstColor = useRef(null);
  const polygonRefSecColor = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    axios.get(`${process.env.REACT_APP_STRAPI_BASE_URL}/api/menu?populate=deep`)
    .then(res => {

      const menuDatas = {
        id: res.data.data.id,
        couleurFond: res.data.data.attributes.couleur_fond,
        couleurFondSecondaire: res.data.data.attributes.couleur_fond_secondaire,
        logoLien: res.data.data.attributes.logo.lien,
        logo: process.env.REACT_APP_STRAPI_BASE_URL + res.data.data.attributes.logo.image.data.attributes.url,
        menu: res.data.data.attributes.menu.map(menu => ({
          texte: menu.texte,
          lien: menu.lien,
        })),
      }
      
      setmenuDataStrapi(menuDatas);
      setLoading(false);

    })
    .catch(err => {
      console.log('error', err);
    });

    function handleScroll() {
      const scrollY = window.scrollY;
      const isScrolled = scrollY > 0;

      // Définition des propriétés d'animation en avance
      const logoWidth = isScrolled ? '50%' : '75%';
      const polygonFirstPoints = isScrolled ? '2560,52 0,38 0,8 2560,8' : '2560,52 0,62 0,8 2560,8';
      const polygonSecondPoints = isScrolled ? '2560,39 0,40 0,0 2560,0' : '2560,39 0,61 0,0 2560,0';

    
      // Animation du logo
      if (logoRef.current) {
        gsap.to(logoRef.current, { width: logoWidth, duration: 0.5 });
      }

      // Animation des polygones
      if (polygonRefFirstColor.current) {
        gsap.to(polygonRefFirstColor.current, { attr: { points: polygonFirstPoints }, duration: 0.5 });
      }
      
      if (polygonRefSecColor.current) {
        gsap.to(polygonRefSecColor.current, { attr: { points: polygonSecondPoints }, duration: 0.5 });
      }
    }

    window.addEventListener('scroll', handleScroll);

    // Nettoyage de l'écouteur d'événements
    return () => window.removeEventListener('scroll', handleScroll);


  }, []);

  // Affichage du menu sur mobile
  function toggleMenu(e) {
    const menu = document.getElementById('menu-mobile');
    const menuBtn = document.getElementById('menu-mobile-btn');
    menu.classList.toggle('flex');
    menu.classList.toggle('hidden');
    menuBtn.classList.toggle('toggle-btn');
  }

  return (
    <>
      {loading ? (
        <div style={{ width: '100vw', height: '100vh', backgroundColor: '#F4F4F4', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {/* Vous pouvez ajouter une animation ou un logo ici si vous le souhaitez */}
        </div>
      ) : (
        <header>

          {/* TOP DU HEADER */}
          <div id="headerMenu" className="z-[60] flex justify-between fixed p-10 pt-4 w-full sm:bg-transparent transition-all ease-in-out duration-500">

            {/* LOGO DETERMINANT */}
            <div id="logo" className="z-10">
              <NavLink to="/">
                <img ref={logoRef} src={menuDataStrapi.logo} alt="logo Determinant" className="w-3/4 "/>
              </NavLink>
            </div>
            
            {/* BOUTON MENU BURGER */}
            <button 
              id="menu-mobile-btn" 
              className="right-0 z-10 block md:hidden cursor-pointer relative w-8 h-8 section-min-heights"
              onClick={toggleMenu}
            >
              <div className="
              bg-black w-8 h-1 rounded 
                absolute top-4 -mt-0.5 
                transition-all duration-500
                before:content-[''] 
              before:bg-black 
                before:w-8 before:h-1 
                before:rounded before:absolute
                before:-translate-x-4 before:-translate-y-3
                before:transition-all before:duration-500
                after:content-['']  
              after:bg-black 
                after:w-8 after:h-1 
                after:rounded after:absolute
                after:-translate-x-4 after:translate-y-3
                after:transition-all after:duration-500"
              >
              </div>
            </button>
            
            {/* MENU DESKTOP */}
            <div className="hidden md:block absolute z-20 top-0 right-0 p-10 pt-6 font-primary" aria-label="dektop">
              <nav>
                {menuDataStrapi.menu && (
                  menuDataStrapi.menu.map((menu, index) => (
                    <NavLink key={index} to={menu.lien} className="text-black pr-10">{menu.texte}</NavLink>
                  ))
                )}
                
              </nav>
            </div>

            {/* BACKGROUND DU BANDEAU DU MENU */}
            <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 2560 66" className="w-full h-[120px] absolute left-0 top-0"  preserveAspectRatio="none">
              <g>
                <g>
                  <polygon 
                    ref={polygonRefFirstColor}
                    points="2560,52 0,62 0,8 2560,8"
                    className="w-full"
                    style={{fill: menuDataStrapi.couleurFondSecondaire}}
                  />
                </g>
                <g>
                  <polygon 
                    ref={polygonRefSecColor}
                    points="2560,39 0,61 0,0 2560,0"
                    className="w-full"
                    style={{fill: menuDataStrapi.couleurFond}}
                  />
                </g>
              </g>
            </svg>

          </div>

          {/* MENU BURGER */}
          <div 
            id="menu-mobile" 
            className="z-[101] fixed left-0 top-[100px] bg-white w-full text-3xl flex-col justify-center origin-top animate-open-menu hidden font-primary"
            onClick={toggleMenu}
          >
            <nav className="flex flex-col min-h-screen items-center py-8 m-auto w-2/3" aria-label="mobile">
              {menuDataStrapi.menu && (
                menuDataStrapi.menu.map((menu, index) => (
                  <NavLink key={index} to={menu.lien} className="hover:opacity:90 text-black w-full text-center py-6">{menu.texte}</NavLink>
                ))
              )}
            </nav>
          </div>

        </header>
      )}
    </>
  );
}

export default Menu;