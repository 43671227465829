import { Fragment,  useEffect,  useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useForm } from "react-hook-form";
import axios from 'axios';

function Devis() {
    const [open, setOpen] = useState(false)
    const cancelButtonRef = useRef(null)
    const [fileName, setFileName] = useState('Choisissez votre fichier...');
    const [infosDevis, setInfosDevis] = useState([]);
    const [loading, setLoading] = useState(true);
    const handleFileChange = (e) => {
        setFileName(e.target.files[0].name);
    }

    const { register, handleSubmit,  formState: { errors } } = useForm();
    const onSubmit = async (data) => {
        axios.post(`${process.env.REACT_APP_STRAPI_BASE_URL}/api/formulaire-devis`, {
            "data": {
                prenom: data.prenom,
                nom: data.nom,
                telephone: data.telephone,
                societe: data.societe,
                codepostal: data.codepostal,
                message: data.message,
                mail: data.mail,
                file: data.file[0]
            }
            
          })
          .then(function (response) {
            console.log(response);
            setOpen(false);
          })
          .catch(function (error) {
            console.log(error);
          });
    };

    

    useEffect(() => {

        // Appel des informations du devis
        axios.get(`${process.env.REACT_APP_STRAPI_BASE_URL}/api/informations-generales-devi?populate=deep`)
        .then(res => {
    
            const infos_devis = {
                id: res.data.data.id,
                bg_devis: process.env.REACT_APP_STRAPI_BASE_URL + res.data.data.attributes.image_fond.data.attributes.url,
                imgClose: process.env.REACT_APP_STRAPI_BASE_URL + res.data.data.attributes.picto_close.data.attributes.url,
                imgBtnDevis: process.env.REACT_APP_STRAPI_BASE_URL + res.data.data.attributes.picto_btn.data.attributes.url,
                txtBtnDevis: res.data.data.attributes.txt_btn,
            };
            setInfosDevis(infos_devis);
            setLoading(false);
    
        })
        .catch(err => {
            console.log('error', err);
        });

    }, []);

    return(
        <>
            {loading ? (
                <div style={{ width: '100vw', height: '100vh', backgroundColor: '#F4F4F4', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {/* Vous pouvez ajouter une animation ou un logo ici si vous le souhaitez */}
                </div>
            ) : (
                <>
                    <div className="cursor-pointer flex flex-col items-center justify-center fixed bottom-8 right-8 z-[100] bg-primary h-32 w-32 3xl:h-48 3xl:w-48 rounded-full animate-wave" onClick={() => setOpen(true)}>
                        <img src={infosDevis.imgBtnDevis} alt="picto_devis" className="w-6 3xl:w-12 pt-4 pb-2 3xl:pb-2"/>
                        <p className="font-primary text-tertiary-100 uppercase text-[10px] 3xl:text-sm text-center pt-0 3xl:pt-0 p-4 3xl:p-10">{infosDevis.txtBtnDevis}</p>
                    </div>
                    <Transition.Root show={open} as={Fragment}>
                        <Dialog as="div" className="relative z-[101]" initialFocus={cancelButtonRef} onClose={setOpen}>
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            >
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                            <div className="flex h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:max-w-1/2">
                                <section id="devis" className="grid grid-cols-1 sm:grid-cols-2 h-1/2 items-center">
                                    {/* Partie gauche formulaire */}
                                    <div className="p-10 3xl:p-20">
                                        <h1 className="font-primary uppercase text-center text-xl 3xl:text-4xl font-bold mb-4 tracking-wide">Demandez votre devis</h1>
                                        <p className="font-secondary text-center">Une réponse vous sera faites sous 48 heures</p>

                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="mt-4 3xl:mt-20 grid grid-cols-2 gap-x-20">
                                                {/* Champ Prénom */}
                                                <div className="relative w-full min-w-[200px] h-11 flex flex-col">
                                                    <input 
                                                        name="prenom"
                                                        autoComplete='off' 
                                                        className="peer bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all border-b placeholder-shown:border-tertiary-200 text-sm pt-4 pb-1.5 border-blue-gray-200 w-full" 
                                                        placeholder=" "
                                                        {...register("prenom", { required: true })}
                                                    />
                                                    <label 
                                                        className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-black leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] after:content[''] after:block after:w-full after:absolute after:-bottom-1.5 after:border-b-2 after:scale-x-0 peer-focus:after:scale-x-100 after:transition-transform after:duration-300 peer-placeholder-shown:leading-[4.25] text-gray-500 peer-focus:text-tertiary-400 after:border-gray-500 peer-focus:after:border-primary">Prénom 
                                                    </label>
                                                    {errors.prenom && <span className="text-[#BE1622]">Le champ prénom est requis</span>}
                                                </div>
                                                {/* Champ Nom  */}
                                                <div className="relative w-full min-w-[200px] h-11 flex flex-col">
                                                    <input 
                                                        name="nom" 
                                                        autoComplete='off' 
                                                        className="peer bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all border-b placeholder-shown:border-tertiary-200 text-sm pt-4 pb-1.5 border-blue-gray-200 w-full" 
                                                        placeholder=" "
                                                        {...register("nom", { required: true })}
                                                    />
                                                    <label 
                                                        className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-black leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] after:content[''] after:block after:w-full after:absolute after:-bottom-1.5 after:border-b-2 after:scale-x-0 peer-focus:after:scale-x-100 after:transition-transform after:duration-300 peer-placeholder-shown:leading-[4.25] text-gray-500 peer-focus:text-tertiary-400 after:border-gray-500 peer-focus:after:border-primary">Nom 
                                                    </label>
                                                    {errors.nom && <span className="text-[#BE1622]">Le champ nom est requis</span>}
                                                </div>
                                            </div>

                                            <div className="mt-4 grid grid-cols-2 gap-x-20">
                                                {/* Champ Adresse mail  */}
                                                <div className="relative w-full min-w-[200px] h-11 flex flex-col">
                                                    <input 
                                                        name="mail" 
                                                        autoComplete='off' 
                                                        className="w-full peer bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all border-b placeholder-shown:border-tertiary-200 text-sm pt-4 pb-1.5 border-blue-gray-200" 
                                                        placeholder=" "
                                                        {...register("mail", { 
                                                            required: "Le champ mail est requis",
                                                            pattern: {
                                                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                                                message: "Le champ mail doit être une adresse e-mail valide"
                                                            }
                                                        })}
                                                    />
                                                    <label 
                                                        className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-black leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] after:content[''] after:block after:w-full after:absolute after:-bottom-1.5 after:border-b-2 after:scale-x-0 peer-focus:after:scale-x-100 after:transition-transform after:duration-300 peer-placeholder-shown:leading-[4.25] text-gray-500 peer-focus:text-tertiary-400 after:border-gray-500 peer-focus:after:border-primary">Adresse mail 
                                                    </label>
                                                    {errors.mail && <span className="text-[#BE1622]">Le champ mail est requis et doit être au bon format</span>}
                                                </div>
                                                {/* Champ Téléphone  */}
                                                <div className="relative w-full min-w-[200px] h-11 flex flex-col">
                                                    <input 
                                                        name="telephone" 
                                                        autoComplete='off' 
                                                        className="w-full peer bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all border-b placeholder-shown:border-tertiary-200 text-sm pt-4 pb-1.5 border-blue-gray-200" 
                                                        placeholder=" "
                                                        {...register("telephone", { required: true })}
                                                    />
                                                    <label 
                                                        className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-black leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] after:content[''] after:block after:w-full after:absolute after:-bottom-1.5 after:border-b-2 after:scale-x-0 peer-focus:after:scale-x-100 after:transition-transform after:duration-300 peer-placeholder-shown:leading-[4.25] text-gray-500 peer-focus:text-tertiary-400 after:border-gray-500 peer-focus:after:border-primary">Téléphone 
                                                    </label>
                                                    {errors.telephone && <span className="text-[#BE1622]">Le champ téléphone est requis</span>}
                                                </div>
                                            </div>

                                            <div className="mt-4 grid grid-cols-2 gap-x-20">
                                                {/* Champ Votre société  */}
                                                <div className="relative w-full min-w-[200px] h-11 flex flex-col">
                                                    <input 
                                                        name="societe" 
                                                        autoComplete='off' 
                                                        className="w-full peer bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all border-b placeholder-shown:border-tertiary-200 text-sm pt-4 pb-1.5 border-blue-gray-200" 
                                                        placeholder=" "
                                                        {...register("societe", { required: true })}
                                                    />
                                                    <label 
                                                        className="flex w-full  h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-black leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] after:content[''] after:block after:w-full after:absolute after:-bottom-1.5 after:border-b-2 after:scale-x-0 peer-focus:after:scale-x-100 after:transition-transform after:duration-300 peer-placeholder-shown:leading-[4.25] text-tertiary-200 peer-focus:text-tertiary-400 after:border-tertiary-200 peer-focus:after:border-primary">Votre société 
                                                    </label>
                                                    {errors.societe && <span className="text-[#BE1622]">Le champ société est requis</span>}
                                                </div>

                                                {/* Champ Code postal  */}
                                                <div className="relative w-full min-w-[200px] h-11 flex flex-col">
                                                    <input 
                                                        name="codepostal" 
                                                        autoComplete='off' 
                                                        className="w-full peer bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all border-b placeholder-shown:border-tertiary-200 text-sm pt-4 pb-1.5 border-blue-gray-200" 
                                                        placeholder=" "
                                                        {...register("codepostal", { required: true })}
                                                    />
                                                    <label 
                                                        className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-black leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] after:content[''] after:block after:w-full after:absolute after:-bottom-1.5 after:border-b-2 after:scale-x-0 peer-focus:after:scale-x-100 after:transition-transform after:duration-300 peer-placeholder-shown:leading-[4.25] text-gray-500 peer-focus:text-tertiary-400 after:border-gray-500 peer-focus:after:border-primary">Code postal 
                                                    </label>
                                                    {errors.telephone && <span className="text-[#BE1622]">Le champ code postal est requis</span>}
                                                </div>
                                            </div>

                                            <div className="mt-4 grid grid-cols-1 mb-10 3xl:mb-14">
                                                {/* Champ Message  */}
                                                    <div className="relative w-full min-w-[200px] flex flex-col">
                                                        <textarea 
                                                            name="message" 
                                                            autoComplete='off' 
                                                            className="peer w-full h-full min-h-[100px] bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 disabled:resize-none transition-all border-b placeholder-shown:border-tertiary-200 text-sm border-blue-gray-200 focus:border-primary !resize-none" 
                                                            placeholder=" "
                                                            {...register("message", { required: true })}
                                                        >
                                                        </textarea>
                                                        <label 
                                                            className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal peer-placeholder-shown:text-black leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] after:content[' '] after:block after:w-full after:absolute after:-bottom-0 left-0 after:border-b-2 after:scale-x-0 peer-focus:after:scale-x-100 after:transition-transform after:duration-300 peer-placeholder-shown:leading-[4.25] text-gray-500 peer-focus:text-tertiary-400 after:border-tertiary-200 peer-focus:after:border-primary">Message
                                                        </label>
                                                    </div>
                                                    {errors.message && <span className="text-[#BE1622]">Il faut renseigner votre message</span>}
                                            </div>

                                            <div className="border-tertiary-200 border-2 flex flex-col aligns-center items-center p-4 3xl:p-10 mb-10 text-center">
                                                {/* Champ joindre un fichier */}
                                                <input 
                                                    type="file" 
                                                    name="file" 
                                                    id="file-5" 
                                                    className="w-[0.1] h-[0.1] opacity-0 overflow-hidden absolute -z-index-10" 
                                                    {...register("file")}
                                                    onChange={handleFileChange}
                                                />
                                                <label htmlFor="file-5" className="text-tertiary-400 max-w-[80%] text-md 3xl:text-xl text-ellipsis inline-blocks overflow-hidden whitespace-nowrap cursor-pointer">
                                                    <svg version="1.1" id="Calque_1"viewBox="0 0 38 39" className="h-10 w-10 m-auto">
                                                        <path className="fill-none stroke-[#006289] stroke-2" d="M3.8,20.2V32c0,1.9,1.5,3.4,3.4,3.4h23.6c1.9,0,3.4-1.5,3.4-3.4V20.2"/>
                                                        <polyline className="fill-none stroke-[#006289] stroke-2" points="12.1,10.8 19,4 25.9,10.8 "/>
                                                        <line className="fill-none stroke-[#006289] stroke-2" x1="19" y1="26.9" x2="19" y2="7.6"/>
                                                    </svg>
                                                    <span>{fileName}</span>
                                                </label>
                                            </div>

                                            <button type="submit" className="w-full uppercase text-white tracking-widest bg-primary px-5 py-5 m-auto block">envoyer</button>
                                        </form>
                                    </div>
                                    {/* Partie droite image */}
                                    <div className="relative h-full hidden sm:block">
                                        <img className="absolute w-full h-full object-cover" src={infosDevis.bg_devis} alt="devis" />
                                    </div>
                                </section>
                                <img className="z-10 absolute right-6 top-6 h-12 sm:h-16 w-12 sm:w-16 bg-primary sm:bg-gold rounded-full p-4 cursor-pointer transition-all duration-200 ease-in-out" src={infosDevis.imgClose} alt="close button"  onClick={() => setOpen(false)}/>
                                
                                </Dialog.Panel>
                                </Transition.Child>
                            </div>
                            </div>
                        </Dialog>
                    </Transition.Root>
                </>
            )}
        </>
    )
    
}
export default Devis;