import { useEffect, useState } from 'react';
import { Parallax } from 'react-parallax';

function MetiersBlocs({ infos_metier }){

    const [isMobile, setIsMobile] = useState(false);
    const [loading, setLoading] = useState(true);
    const imgClassName = isMobile ? 'mobile-class' : 'desktop-class';
    const updateScreen = () => {
        setIsMobile(window.innerWidth <= 640);
    }

    useEffect(() => {
        
        updateScreen();
        window.addEventListener('resize', updateScreen);
        if (infos_metier && Object.keys(infos_metier).length > 0) {
            setLoading(false); 
        }
        return () => window.removeEventListener('resize', updateScreen);

    }, [infos_metier]);

    

    return (
        <>
            {loading ? (
                <div style={{ width: '100vw', height: '100vh', backgroundColor: '#F4F4F4', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {/* Vous pouvez ajouter une animation ou un logo ici si vous le souhaitez */}
                </div>
            ) : (
                <section id="metiers">
                    {infos_metier.map(( metier ) => (
                        <div key={metier.id}>
                            {/* Header */}
                            <div className="flex flex-col items-center justify-center align-center text-center text-white pb-24 pt-20">
                                {/* image */}
                                {imgClassName === 'mobile-class' ?
                                    <img 
                                        src={metier.imgMobile} 
                                        alt="placeholder" 
                                        className="w-full"
                                    />
                                    :
                                    <div data-aos="fade-up">
                                        <Parallax 
                                            bgImage={metier.img} 
                                            className="h-[700px] w-full border-b-[20px] border-primary" 
                                            strength={300}>
                                        </Parallax>
                                    </div>
                                }                 
                                {/* Texte */}
                                <div className="absolute">
                                    <h2 className="m-auto w-5/6 text-3xl pb-5 md:text-4xl 2xl:text-5xl 3xl:text-7xl lg:pb-5 xl:pb-10 font-bold lg:font-normal font-primary" data-aos="fade-down" data-aos-duration="500">{metier.titre}</h2>
                                    <hr className="w-1/3 m-auto border-1 border-white" data-aos="fade-in" data-aos-duration="2000"></hr>
                                    <p className="text-lg pt-5 w-5/6  md:text-lg lg:text-xl xl:text-xl 3xl:text-3xl lg:pt-10 xl:pt-10 sm:w-2/3 m-auto font-primary bold italic" dangerouslySetInnerHTML={{ __html: metier.description }} data-aos="fade-up" data-aos-duration="500"></p>
                                </div> 
                            </div>
                            {/* Colonnes */}
                            <div className="flex-col sm:flex-row flex w-2/3 m-auto">
                                {metier.premiere_colonne && (
                                    <div className="w-full pb-10 sm:pb-10 sm:pr-10" data-aos="fade-right" data-aos-duration="500">
                                        <h2 className="font-primary text-primary mb-5 text-2xl 2xl:text-3xl 3xl:text-4xl">{metier.premiere_colonne.titre_colonne}</h2>
                                        <div className="font-primary text-secondary text-center text-lg sm:text-xl w-2/3 m-auto metier-liste" dangerouslySetInnerHTML={{ __html: metier.premiere_colonne.liste_colonne }}>
                                        </div>
                                    </div>
                                )}
                                {metier.deuxieme_colonne && (
                                    <div className="w-full sm:pl-5" data-aos="fade-left" data-aos-duration="500">
                                        <h2 className="font-primary text-primary mb-5 text-2xl 2xl:text-3xl 3xl:text-4xl">{metier.deuxieme_colonne.titre_colonne}</h2>
                                        <div className="font-primary text-secondary text-center text-lg sm:text-xl w-2/3 m-auto metier-liste" dangerouslySetInnerHTML={{ __html: metier.deuxieme_colonne.liste_colonne }}>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* Engagements */}
                            {metier.engagements && (
                                <div className="w-2/3 m-auto sm:pl-8 border-2 rounded-3xl p-5 mt-10 mb-16" data-aos="fade-up" data-aos-duration="500">
                                    <h2 className="font-primary text-primary mb-5 text-2xl 2xl:text-3xl 3xl:text-4xl">{metier.engagements.titre_colonne}</h2>
                                    <div className="font-primary text-secondary text-left text-lg sm:text-xl w-full metier-liste-engagement" dangerouslySetInnerHTML={{ __html: metier.engagements.liste_colonne }}>
                                    </div>
                                </div>
                            )}
                            {/* Autres */}
                            {metier.conclusion && (
                                <div className='w-2/3 m-auto'>
                                    <p className="pb-5 text-xl">{metier.conclusion}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </section>
            )}
        </>
    )
}
export default MetiersBlocs;