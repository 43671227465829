import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import axios from 'axios';

function MetiersBlocs(){

    //Récupération des données depuis STRAPI
    const [rd_datas, setRDDatas] = useState([]);
    const [isMobile, setIsMobile] = useState(false);
    const [loading, setLoading] = useState(true);
    const imgClassName = isMobile ? 'mobile-class' : 'desktop-class';
    const updateScreen = () => {
        setIsMobile(window.innerWidth <= 640);
    }
    const [currentIndices, setCurrentIndices] = useState(0);

    const prevSlide = (carouselIndex, slidesLength) => {
        setCurrentIndices(indices =>
            indices.map((currentIndex, index) => 
                index === carouselIndex
                    ? currentIndex - 1 < 0 
                        ? slidesLength - 1 
                        : currentIndex - 1
                    : currentIndex)
            );
    };

    const nextSlide = (carouselIndex, slidesLength) => {
        setCurrentIndices(indices =>
            indices.map((currentIndex, index) => 
                index === carouselIndex
                    ? currentIndex + 1 === slidesLength 
                        ? 0 
                        : currentIndex + 1
                    : currentIndex)
            );
    };

    // Vérifier si l'écran est mobile lors du montage du composant
    useEffect(() => {


        axios.get(`${process.env.REACT_APP_STRAPI_BASE_URL}/api/page-r-and-ds?populate=deep`)
        .then(res => {

            let RD_datas = res.data.data.map((RD) => {
                return {
                    id: RD.id,
                    header: {
                        titre: RD.attributes.Header.titre,
                        description: RD.attributes.Header.description,
                        img: process.env.REACT_APP_STRAPI_BASE_URL + RD.attributes.Header.image.data.attributes.url,
                        imgMobile: process.env.REACT_APP_STRAPI_BASE_URL + RD.attributes.Header.image_mobile.data.attributes.url,
                    },
                    avantages: {
                        titre: RD.attributes.avantages.titre ? RD.attributes.avantages.titre : null,
                        image: RD.attributes.avantages.image.data ? 
                            process.env.REACT_APP_STRAPI_BASE_URL + RD.attributes.avantages.image.data.attributes.url :
                            null,
                        video: RD.attributes.avantages.lien_video ? {
                            picto_video: process.env.REACT_APP_STRAPI_BASE_URL + RD.attributes.avantages.lien_video.picto.data[0].attributes.url,
                            texte_lien: RD.attributes.avantages.lien_video.texte_url,
                            video_url: RD.attributes.avantages.lien_video.url, 
                        } : null,
                        liste_avantages : RD.attributes.avantages.liste_avantages ? {
                            avantages: RD.attributes.avantages.liste_avantages.map(avantage => avantage),
                        } : null,
                    },
                    encart: RD.attributes.encart ? RD.attributes.encart : null,
                    carousel: RD.attributes.carousel.data ? {
                        imgs: RD.attributes.carousel.data.map(img => process.env.REACT_APP_STRAPI_BASE_URL + img.attributes.url),
                    } : null,
                }
            });
            
            setRDDatas(RD_datas);
            setLoading(false);

            // Initialisation de currentIndices avec des valeurs par défaut
            setCurrentIndices(RD_datas.map(() => 0));

            
        })
        .catch(err => {
            console.log('error', err);
        });

        updateScreen();
        window.addEventListener('resize', updateScreen);
        return () => window.removeEventListener('resize', updateScreen);

    }, []);
    return (
        <>
            {loading ? (
                <div style={{ width: '100vw', height: '100vh', backgroundColor: '#F4F4F4', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {/* Vous pouvez ajouter une animation ou un logo ici si vous le souhaitez */}
                </div>
            ) : (
                <section id="rd">
                    {rd_datas.map((rd, carouselIndex) => (
                        <div key={rd.id} className='relative w-full'>
                            {/* Header */}
                            <div className="flex flex-col items-center justify-center align-center text-center text-white pb-10 pt-0">
                                {imgClassName === 'mobile-class' ?
                                    <img src={rd.header.imgMobile} alt="placeholder" className="w-full"/>
                                    :
                                    <img src={rd.header.img} alt="placeholder" className="w-full"/>
                                }
                                <div className="absolute">
                                    {rd.header.titre && (
                                        <h2 
                                            className="text-4xl pb-5 md:text-4xl lg:text-6xl 2xl:text-6xl 3xl:text-7xl lg:pb-10 xl:pb-16 bold font-primary" 
                                            data-aos="fade-down" 
                                            data-aos-duration="500">
                                                {rd.header.titre}
                                        </h2>
                                    )}
                                    {rd.header.description && (
                                        <div>
                                            <hr className="w-1/3 m-auto border-1 border-white" data-aos="fade-in" data-aos-duration="2000"></hr>
                                            <p className="text-xl pt-5 w-5/6  md:text-lg lg:text-xl xl:text-2xl 3xl:text-3xl lg:pt-10 xl:pt-16 sm:w-2/3 m-auto font-primary bold italic" dangerouslySetInnerHTML={{__html: rd.header.description}} data-aos="fade-up" data-aos-duration="500"></p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='grid grid-cols-1 sm:grid-cols-2 items-center'>
                                {/* Texte */}
                                <div>
                                    {rd.avantages.liste_avantages &&  (
                                        <div className="flex-col sm:flex-row flex w-2/3 m-auto">
                                            <div className="w-full pb-10 sm:pb-10 sm:pr-10 metier-liste">
                                                <h2 className="font-primary text-primary mb-5 text-2xl 2xl:text-3xl 3xl:text-4xl" data-aos="fade-right" data-aos-duration="500">{rd.avantages.titre}</h2>
                                                <ul className="custom-bullet-list text-secondary text-lg 3xl:text-xl"data-aos="fade-right" data-aos-duration="750">
                                                    {rd.avantages.liste_avantages.avantages.map((avantage, index) => 
                                                        <li key={index}>{avantage.avantage}</li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                    {/* Video */}
                                    {rd.avantages.video.texte_lien && (
                                        <NavLink to={rd.avantages.video.url} className="flex-col sm:flex-row flex w-2/3 m-auto items-center"data-aos="fade-right" data-aos-duration="1000">
                                                <img src={rd.avantages.video.picto_video} alt="placeholder" className="max-h-10 mr-2"/>
                                                <p className='text-primary'>{rd.avantages.video.texte_lien}</p>
                                        </NavLink>
                                    )}
                                </div>
                                <div className='m-auto'>
                                    {/* Visuel */}
                                    {rd.avantages.image && ( 
                                        <div style={{ marginBottom : '0px' }} data-aos="fade-left" data-aos-duration="1000">
                                            <img src={rd.avantages.image} alt="placeholder" className='max-w-[75%] bottom-[-90px] relative'/>
                                        </div>
                                    )}
                                </div>
                                
                            </div>
                            {/* Encart */}
                            {rd.encart && (
                                <div className="w-2/3 m-auto sm:pl-5 border-2 rounded-3xl p-5 mt-10" data-aos="fade-up" data-aos-duration="1000">
                                    <p>{rd.encart}</p>
                                </div>
                            )}
                            {/* carousel */}
                            {rd.carousel  && (
                                <div id="carousel" className="m-auto py-32 flex flex-row justify-center items-center" data-aos="fade-in" data-aos-duration="1000">
                                    {/* Fléche gauche */}
                                    <div 
                                        className="left-0 p-2 py-5 cursor-pointer"
                                        onClick={() => prevSlide(carouselIndex, rd.carousel.imgs.length)}
                                    >
                                        <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" className="fill-primary h-14 w-14 rotate-180" x="0px" y="0px"
                                            viewBox="0 0 79 109.68">
                                        <g>
                                            <g id="Page-1_00000170247475886868935120000009026497317448903058_">
                                                
                                                    <g id="Dribbble-Light-Preview_00000155120560416221268030000005034868085075899289_" transform="translate(-425.000000, -6679.000000)">
                                                    <g id="icons_00000152966580795315174870000012597865354438311072_" transform="translate(56.000000, 160.000000)">
                                                        <path id="arrow_x5F_right-_x5B__x23_333_x5D__00000121966617708372736580000016210146770600540069_" d="
                                                            M399.72,6547.28l-3.88,3.92l23.04,24.01l-2.46,2.56l0.01-0.01l-20.47,21.33l3.82,3.98c5.66-5.89,21.46-22.35,26.74-27.85
                                                            C422.6,6571.12,426.43,6575.1,399.72,6547.28"/>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        </svg>
                                    </div>
                                    {/* Image précédente */}
                                    <div className='relative'>
                                        <img 
                                            src={rd.carousel.imgs[(currentIndices[carouselIndex] - 1 + rd.carousel.imgs.length) % rd.carousel.imgs.length]} 
                                            alt='placeholder' 
                                            className='rounded-full max-h-48 max-w-48'
                                        />
                                    </div>
                                    {/* Image actuelle */}
                                    <div className='relative px-2 sm:px-5'>
                                        <img 
                                            src={rd.carousel.imgs[currentIndices[carouselIndex]]} 
                                            alt='placeholder' 
                                            className='rounded-full max-h-48 max-w-48'
                                        />
                                        {rd.id % 2 === 0 ? (
                                            <div className='absolute w-16 h-16 bottom-14 sm:w-40 sm:h-40 md:w-[200px] md:h-[200px]   sm:bottom-[50%] md:bottom-[30%] left-0 rounded-full -z-10 bg-primary'></div>
                                        ):(
                                            <div className='absolute w-16 h-16 bottom-14 sm:w-40 sm:h-40 sm:bottom-[30%] right-0 rounded-full -z-10 bg-primary'></div>
                                        )}
                                        
                                    </div>

                                    {/* Image suivante */}
                                    <div className='relative'>
                                        <img 
                                            src={rd.carousel.imgs[(currentIndices[carouselIndex] + 1) % rd.carousel.imgs.length]} 
                                            alt='placeholder' 
                                            className='rounded-full max-h-48 max-w-48'
                                        />
                                    </div>
                                    {/* Flèche droite */}
                                    <div 
                                        className=" right-0 p-2 py-5 cursor-pointer"
                                        onClick={() => nextSlide(carouselIndex, rd.carousel.imgs.length)}
                                    >
                                        <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" className="fill-primary h-14 w-14" x="0px" y="0px"
                                            viewBox="0 0 79 109.68">
                                        <g>
                                            <g id="Page-1_00000170247475886868935120000009026497317448903058_">
                                                
                                                    <g id="Dribbble-Light-Preview_00000155120560416221268030000005034868085075899289_" transform="translate(-425.000000, -6679.000000)">
                                                    <g id="icons_00000152966580795315174870000012597865354438311072_" transform="translate(56.000000, 160.000000)">
                                                        <path id="arrow_x5F_right-_x5B__x23_333_x5D__00000121966617708372736580000016210146770600540069_" d="
                                                            M399.72,6547.28l-3.88,3.92l23.04,24.01l-2.46,2.56l0.01-0.01l-20.47,21.33l3.82,3.98c5.66-5.89,21.46-22.35,26.74-27.85
                                                            C422.6,6571.12,426.43,6575.1,399.72,6547.28"/>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        </svg>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </section>
            )}
        </>
    )
}
export default MetiersBlocs;