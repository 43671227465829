import RdBlocs from "../components/RdBlocs";

import { useEffect, useState } from "react";
import axios from "axios";
import Devis from "./devis";

import AOS from 'aos';
import 'aos/dist/aos.css';


function Rd() {

    const [rd_infos_generales_datas, set_RD_infos_generales_datas] = useState([]);

    useEffect(() => {

        AOS.init({
            duration: 2000,
        });

        axios.get(`${process.env.REACT_APP_STRAPI_BASE_URL}/api/informations-generale?populate=deep`)
        .then(res => {
        
            let RD_datas_generales = {
                id: res.data.data.id,
                titre: res.data.data.attributes.titre,
                description: res.data.data.attributes.description,
                encart: {
                    imgBG: process.env.REACT_APP_STRAPI_BASE_URL + res.data.data.attributes.Encart.imageBG.data.attributes.url,
                    encart_infos: res.data.data.attributes.Encart.encart_infos.map(info => ({
                        titre: info.titre,
                        description: info.description,
                        icon: process.env.REACT_APP_STRAPI_BASE_URL + info.icon.data.attributes.url
                    })),            
                },
                partenaires: {
                    imgs: res.data.data.attributes.partenaires.data.map(img => process.env.REACT_APP_STRAPI_BASE_URL + img.attributes.url),
                },

            };
            
            set_RD_infos_generales_datas(RD_datas_generales);
        })
        .catch(err => {
            console.log('error', err);
        });

    }, []);

    return (
        <>
            <div data-aos="fade-up">
                <div className="pb-48"></div>
                {/* Titre général */}
                <div className="flex justify-center w-2/3 m-auto pb-20">
                    <h1 className="font-primary text- uppercase text-center text-3xl sm:text-5xl pb-5 border-b-4 border-primary tracking-widest transition-all ease-in-out duration-500">{rd_infos_generales_datas.titre}</h1>
                </div>

                {/* Introduction */}
                <div className="font-secondary text-center text-lg sm:text-xl w-2/3 m-auto"  dangerouslySetInnerHTML={{ __html: rd_infos_generales_datas.description }}>
                </div>

                {/* Projets */}
                <div className="pb-24"></div>
                <h3 className="uppercase font-primary text-primary m-auto w-2/3 text-center mb-5 text-lg font-bold">nos projets en cours</h3>
                <div className="text-center text-lg sm:text-xl w-2/3 m-auto bg-projet p-10 rounded-lg" style={{ backgroundImage: `url(${rd_infos_generales_datas.encart ? rd_infos_generales_datas.encart.imgBG : 'default_image_url'})` }}>

                    {rd_infos_generales_datas.encart && (
                        rd_infos_generales_datas.encart.encart_infos.map((info, index) => (
                            <div key={index}>
                                <div className="flex flex-row items-center justify-center" data-aos="fade-up" data-aos-duration="800">
                                    <img src={info.icon} alt="icon brevet" className="h-10 w-10"/>
                                    <h3 className="font-secondary uppercase text-white text-md pl-2">{info.titre}</h3>
                                </div>
                                <p className="text-white text-sm mb-10 mt-0" dangerouslySetInnerHTML={{ __html: info.description }} data-aos="fade-up" data-aos-duration="1000"></p>
                            </div>
                        ))
                    )}
                    
                </div>

                {/* Partenaires */}
                <div className="w-full sm:w-2/3 m-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 3xl:grid-cols-4 gap-4 pt-20 pb-48" data-aos="fade-in" data-aos-duration="1000">
                    {rd_infos_generales_datas.partenaires && (
                        rd_infos_generales_datas.partenaires.imgs.map((img, index) => (
                            <div key={index} className="menu-direction pt-2 px-16 sm:px-0">
                                <img src={img} alt="none" className="border-primary border"/>
                            </div>
                        ))
                    )}
                </div>
            </div>
            <RdBlocs/>
            <div className="pb-24"></div>
            
        </>
    )    
}
export default Rd;