import AOS from 'aos';
import 'aos/dist/aos.css';

function Entites( {infos_entites} ) {

    function handleMouseOver(event) {
        event.target.nextSibling.style.opacity = 0;
    }
    function handleMouseOut(event) {
        event.target.nextSibling.style.opacity = 1;
    }

    AOS.init({
      duration: 1000, 
    });

    return (
        <section id="entites" data-aos="fade-up" data-aos-duration="1000">
          {/* Marge */}
          <div className="pb-32"></div>
          {/* Titre général */}
          <div className="flex justify-center">
            <h1 className="font-primary text-black uppercase text-center text-4xl sm:text-5xl pb-5 border-b-4 border-primary tracking-widest transition-all ease-in-out duration-500">Entités</h1>
          </div>

          {/* Blocs entités Strapi*/}
          <div className="flex flex-col sm:flex-row justify-center w-full px-10 sm:px-0 mt-16">
            {infos_entites.map( entite => (
              <div key={entite.id}>
                <img 
                  src={ process.env.REACT_APP_STRAPI_BASE_URL + entite.logo_entite.data.attributes.url }
                  alt={ entite.nom }
                  className="w-3/4 m-auto border-2 border-primary hover:scale-110 transition-all ease-in-out duration-100 cursor-pointer"
                  onMouseOver={handleMouseOver}  
                  onMouseOut={handleMouseOut}
                />
                <p className="w-3/4 m-auto text-left italic text-primary">{ entite.legende }</p>
              </div>
            ))}
          </div>
          
        </section>
    );
}
export default Entites;