import React, { useEffect, useState } from 'react';
import axios from "axios";
import Devis from "./devis";


function Contact() {

  const [contact_datas, set_contact_datas] = useState([]);
  const [loading, setLoading] = useState(true);


  // Vérifier si l'écran est mobile lors du montage du composant
  useEffect(() => {


    axios.get(`${process.env.REACT_APP_STRAPI_BASE_URL}/api/informations-generales-contact?populate=deep`)
    .then(res => {

        let contact_datas =  {
            
              id: res.data.data.id,
              imgCarte: process.env.REACT_APP_STRAPI_BASE_URL + res.data.data.attributes.imgCarte.data.attributes.url,
              infos_contacts: res.data.data.attributes.legende ? 
              res.data.data.attributes.legende.map(info => ({
                  ville: info.ville,
                  adresse: info.adresse,
                  email: info.email,
                  telephone: info.telephone,
                  image: process.env.REACT_APP_STRAPI_BASE_URL + info.image.data.attributes.url
              })) : null,
            
        };
        
        set_contact_datas(contact_datas);
        setLoading(false);

        
    })
    .catch(err => {
        console.log('error', err);
    });

  }, []);



  return (
    <>
      {loading ? (
        <div style={{ width: '100vw', height: '100vh', backgroundColor: '#F4F4F4', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {/* Vous pouvez ajouter une animation ou un logo ici si vous le souhaitez */}
        </div>
      ) : (
        <>
          <div className="pb-48"></div>
          <div className="grid grid-cols-1 sm:grid-cols-2 content">
            <div className="flex flex-col items-center text-center p-10 mt-0 sm:mt-10" data-aos="fade-right" data-aos-duration="1000">
              {/* Carte de la France */}
              <img src={contact_datas.imgCarte} alt="Carte" className="w-2/3 h-auto"/>
            </div>
            <div className="deuxiemeBloc"> 
              <div className="bg-primary text-white text-center flex flex-row flex-wrap flex-start w-full mt-10 p-10 3xl:p-16 pb-0 justify-center" >
                {/* Légende */}
                {contact_datas.infos_contacts && (
                  contact_datas.infos_contacts.map((contact, index) => (
                    <div key={index} className="p-10 3xl:p-20 3xl:pb-0 pb-4 pt-4" data-aos="fade-left" data-aos-duration="1000">
                      <div className="flex flex-col text-left relative pb-10 3xl:pb-10 w-[200px]">
                          <h2 className="font-primary text-xl 3xl:text-2xl pb-4 font-bold uppercase">{contact.ville}</h2>
                          <p className="pb-2 text-sm 3xl:text-lg">{contact.adresse}<br/>{contact.telephone}<br/>{contact.email}</p>
                          <img src={contact.image}  
                            alt="carte"
                            className="h-32 w-32 absolute left-[-70px] top-[-12px] z-[-1]"
                          />
                      </div>
                    </div>
                  ))
                )}
                
                <div id="bande_Couleur" className={`bg-[#BE1622]  h-full w-1/6 absolute right-0 top-0 -z-10 hidden sm:block`}></div>
              </div>
              
            </div>
            
          </div>
          
        </>
      )}
    </>
  );
}
export default Contact;
