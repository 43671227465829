import { useEffect, useState } from 'react';
import axios from 'axios';
import { Parallax } from 'react-parallax';
import AOS from 'aos';
import 'aos/dist/aos.css';

function DomainesBlocs(){

    
    const [isMobile, setIsMobile] = useState(false);
    const [domaineDatas, setDomaineDatas] = useState([]);
    const [currentIndices, setCurrentIndices] = useState([]);
    const [loading, setLoading] = useState(true);

    const imgClassName = isMobile ? 'mobile-class' : 'desktop-class';
    const updateScreen = () => {
        setIsMobile(window.innerWidth <= 990);
    }
    
    
    const prevSlide = (carouselIndex, slidesLength) => {
        setCurrentIndices(indices =>
            indices.map((currentIndex, index) => 
                index === carouselIndex
                    ? currentIndex - 1 < 0 
                        ? slidesLength - 1 
                        : currentIndex - 1
                    : currentIndex)
            );
    };

    const nextSlide = (carouselIndex, slidesLength) => {
        setCurrentIndices(indices =>
            indices.map((currentIndex, index) => 
                index === carouselIndex
                    ? currentIndex + 1 === slidesLength 
                        ? 0 
                        : currentIndex + 1
                    : currentIndex)
            );
    };


    

    // Vérifier si l'écran est mobile lors du montage du composant
    useEffect(() => {
        AOS.init({
            duration: 1000, 
        });

        axios.get(`${process.env.REACT_APP_STRAPI_BASE_URL}/api/page-domaine-articles?populate=*`)
        .then(res => {

            let domaine_datas = res.data.data.map((domaine) => {
                return {
                    id: domaine.id,
                    titre: domaine.attributes.titre,
                    img: process.env.REACT_APP_STRAPI_BASE_URL + domaine.attributes.img.data.attributes.url,
                    imgMobile: process.env.REACT_APP_STRAPI_BASE_URL + domaine.attributes.imgMobile.data.attributes.url,
                    description: domaine.attributes.description,
                    titre_article: domaine.attributes.titre_article,
                    liste_dommage : domaine.attributes.liste_dommage ? {
                        titre_colonne: domaine.attributes.liste_dommage.titre_colonne,
                        liste_colonne: domaine.attributes.liste_dommage.liste_colonne,
                    } : null,
                    liste_dommages : domaine.attributes.liste_dommages ? {
                        dommages: domaine.attributes.liste_dommages.map(dommage => dommage),
                    } : null,
                    carousel: domaine.attributes.carousel.data ? {
                        imgs: domaine.attributes.carousel.data.map(img => process.env.REACT_APP_STRAPI_BASE_URL + img.attributes.url),
                    } : null,
                    process: domaine.attributes.process.data &&
                    process.env.REACT_APP_STRAPI_BASE_URL + domaine.attributes.process.data.attributes.url,
                }
            });
            
            setDomaineDatas(domaine_datas);

            // Initialisation de currentIndices avec des valeurs par défaut
            setCurrentIndices(domaine_datas.map(() => 0));
            setLoading(false);

            
        })
        .catch(err => {
            console.log('error', err);
        });

        updateScreen();
        window.addEventListener('resize', updateScreen);
        return () => window.removeEventListener('resize', updateScreen);
    }, []);


    return (
        <>
            {loading ? (
                <div style={{ width: '100vw', height: '100vh', backgroundColor: '#F4F4F4', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {/* Vous pouvez ajouter une animation ou un logo ici si vous le souhaitez */}
                </div>
            ) : (
                <section id="metiers">
                    {domaineDatas.map((domaine, index) => (
                        <div key={domaine.id}>
                            {/* Header */}
                            <div className="flex flex-col items-center justify-center align-center text-center text-white pb-24 pt-20">
                                {/* image */}
                                {imgClassName === 'mobile-class' ?
                                    <img 
                                        src={domaine.imgMobile} 
                                        alt="placeholder" 
                                        className="w-full"
                                    />
                                    :
                                    <div data-aos="fade-up">
                                        <Parallax 
                                            bgImage={domaine.img} 
                                            className="h-[700px] w-full border-b-[20px] border-primary" 
                                            strength={300}>
                                        </Parallax>
                                    </div>
                                }  
                                {/* Texte */}
                                <div className="absolute">
                                    <h2 className="m-auto w-5/6 text-3xl pb-5 md:text-4xl 2xl:text-5xl 3xl:text-7xl lg:pb-5 xl:pb-10 font-bold lg:font-normal font-primary" data-aos="fade-down" data-aos-duration="500">{domaine.titre}</h2>
                                    <hr className="w-1/3 m-auto border-1 border-white" data-aos="fade-in" data-aos-duration="2000"></hr>
                                    <p className="text-lg pt-5 w-5/6  md:text-lg lg:text-xl xl:text-xl 3xl:text-3xl lg:pt-10 xl:pt-10 sm:w-2/3 m-auto font-primary bold italic" dangerouslySetInnerHTML={{ __html: domaine.description }} data-aos="fade-up" data-aos-duration="500"></p>
                                </div> 
                            </div>
                            {/* Titre de l'article */}
                            {domaine.titre_article && (
                                <div data-aos="fade-down" data-aos-duration="500">
                                    <h3 className='w-2/3 m-auto pb-10 font-primary text-primary' dangerouslySetInnerHTML={{__html: domaine.titre_article}}></h3>
                                </div>
                            )}
                            {/* Process */}
                            {domaine.process && (
                                <div className="w-full h-full relative flex justify-center mb-20" data-aos="fade-down" data-aos-duration="500">
                                    <img src={domaine.process} alt="placeholder" className="w-full m-auto"/>
                                </div>
                            )}
                            
                            {/* Colonne */}
                            {domaine.liste_dommages.dommages && (
                                <div className="w-full pb-10 sm:pb-10 sm:pr-10 metier-liste" data-aos="fade-right" data-aos-duration="500">
                                    <ul className="font-primary text-secondary text-center text-lg sm:text-xl w-2/3 m-auto ">
                                        {domaine.liste_dommages.dommages.map((item, index) => (
                                            <li key={index}>{item.dommage}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            
                            {/* carousel */}
                            {domaine.carousel && (
                                <div id="carousel" className="m-auto py-10 flex flex-row justify-center items-center" data-aos="fade-in" data-aos-duration="500">
                                    {/* Fléche gauche */}
                                    <div 
                                        className="left-0 p-2 py-5 cursor-pointer"
                                        onClick={() => prevSlide(index, domaine.carousel.imgs.length)}
                                    >
                                        <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" className="fill-primary h-14 w-14 rotate-180" x="0px" y="0px"
                                            viewBox="0 0 79 109.68">
                                        <g>
                                            <g id="Page-1_00000170247475886868935120000009026497317448903058_">
                                                
                                                    <g id="Dribbble-Light-Preview_00000155120560416221268030000005034868085075899289_" transform="translate(-425.000000, -6679.000000)">
                                                    <g id="icons_00000152966580795315174870000012597865354438311072_" transform="translate(56.000000, 160.000000)">
                                                        <path id="arrow_x5F_right-_x5B__x23_333_x5D__00000121966617708372736580000016210146770600540069_" d="
                                                            M399.72,6547.28l-3.88,3.92l23.04,24.01l-2.46,2.56l0.01-0.01l-20.47,21.33l3.82,3.98c5.66-5.89,21.46-22.35,26.74-27.85
                                                            C422.6,6571.12,426.43,6575.1,399.72,6547.28"/>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        </svg>
                                    </div>
                                    {/* Image précédente */}
                                    <div className='relative'>
                                        <img 
                                            src={domaine.carousel.imgs[(currentIndices[index] - 1 + domaine.carousel.imgs.length) % domaine.carousel.imgs.length]} 
                                            alt='placeholder' 
                                            className='rounded-full max-h-48 max-w-48'
                                        />
                                    </div>
                                    {/* Image actuelle */}
                                    <div className='relative px-2 sm:px-5'>
                                        <img 
                                            src={domaine.carousel.imgs[currentIndices[index]]} 
                                            alt='placeholder' 
                                            className='rounded-full max-h-48 max-w-48'
                                        />
                                        {domaine.id % 2 === 0 ? (
                                            <div className='absolute w-16 h-16 bottom-14 sm:w-40 sm:h-40 md:w-[200px] md:h-[200px]   sm:bottom-[50%] md:bottom-[30%] left-0 rounded-full -z-10 bg-primary'></div>
                                        ):(
                                            <div className='absolute w-16 h-16 bottom-14 sm:w-40 sm:h-40 sm:bottom-[30%] right-0 rounded-full -z-10 bg-primary'></div>
                                        )}
                                        
                                    </div>

                                    {/* Image suivante */}
                                    <div className='relative'>
                                        <img 
                                            src={domaine.carousel.imgs[(currentIndices[index] + 1) % domaine.carousel.imgs.length]} 
                                            alt='placeholder' 
                                            className='rounded-full max-h-48 max-w-48'
                                        />
                                    </div>
                                    {/* Flèche droite */}
                                    <div 
                                        className=" right-0 p-2 py-5 cursor-pointer"
                                        onClick={() => nextSlide(index, domaine.carousel.imgs.length)}
                                    >
                                        <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" className="fill-primary h-14 w-14" x="0px" y="0px"
                                            viewBox="0 0 79 109.68">
                                        <g>
                                            <g id="Page-1_00000170247475886868935120000009026497317448903058_">
                                                
                                                    <g id="Dribbble-Light-Preview_00000155120560416221268030000005034868085075899289_" transform="translate(-425.000000, -6679.000000)">
                                                    <g id="icons_00000152966580795315174870000012597865354438311072_" transform="translate(56.000000, 160.000000)">
                                                        <path id="arrow_x5F_right-_x5B__x23_333_x5D__00000121966617708372736580000016210146770600540069_" d="
                                                            M399.72,6547.28l-3.88,3.92l23.04,24.01l-2.46,2.56l0.01-0.01l-20.47,21.33l3.82,3.98c5.66-5.89,21.46-22.35,26.74-27.85
                                                            C422.6,6571.12,426.43,6575.1,399.72,6547.28"/>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        </svg>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </section>
            )}
        </>
    )
}
export default DomainesBlocs;