import { useEffect, useState } from "react";
import axios from "axios";
import Devis from "./devis";

import AOS from 'aos';
import 'aos/dist/aos.css';

function Process() { 

    const [process_infos_generales_datas, set_process_infos_generales_datas] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        AOS.init({
            duration: 2000,
        });

        axios.get(`${process.env.REACT_APP_STRAPI_BASE_URL}/api/informations-generales-process?populate=*`)
        .then(res => {

            let process_datas_generales = {
                titre: res.data.data.attributes.titre,
                description: res.data.data.attributes.description,
                imgProcess: process.env.REACT_APP_STRAPI_BASE_URL + res.data.data.attributes.process.data.attributes.url,
            };
            
            set_process_infos_generales_datas(process_datas_generales);
            setLoading(false);
            AOS.refresh();
        })
        .catch(err => {
            console.log('error', err);
        });

    }, []);

    return (
        <>
            {loading ? (
                <div style={{ width: '100vw', height: '100vh', backgroundColor: '#F4F4F4', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {/* Vous pouvez ajouter une animation ou un logo ici si vous le souhaitez */}
                </div>
            ) : (
                <>
                    <div data-aos="fade-up">
                        {/* Titre général */}
                        <div className="pb-48"></div>
                        <div className="flex justify-center w-2/3 m-auto pb-20">
                            <h1 className="font-primary text- uppercase text-center text-3xl sm:text-5xl pb-5 border-b-4 border-primary tracking-widest transition-all ease-in-out duration-500">{process_infos_generales_datas.titre}</h1>
                        </div>

                        {/* Introduction */}
                        <div className="font-primary text-center text-lg sm:text-xl w-2/3 m-auto">
                            <p className="text-primary my-10 mt-0" dangerouslySetInnerHTML={{ __html: process_infos_generales_datas.description }}></p>
                        </div>
                    </div>
                    {/* progress */}
                    <div className="flex justify-center m-auto" data-aos="fade-down">
                        {process_infos_generales_datas.imgProcess && (
                            <img src={process_infos_generales_datas.imgProcess} alt="process" className="w-full h-full"/>
                        )}
                    </div>
                    <div className="pb-24"></div>
                    
                </>
            )}
        </>
    )
}
export default Process;