import DomainesBlocs from "../components/DomainesBlocs";
import { useEffect, useState } from "react";
import axios from "axios";
import Devis from "./devis";

import AOS from 'aos';
import 'aos/dist/aos.css';

function Domaines() {
    
    const [domaineIntroDatacStrapi, setDomaineIntroDataStrapi] = useState([]);

    useEffect(() => {

        AOS.init({
            duration: 2000,
        });

        axios.get(`${process.env.REACT_APP_STRAPI_BASE_URL}/api/informations-generales-domaine`)
        .then(res => {

        let domaine_intro_datas = {
            id: res.data.data.id,
            titre: res.data.data.attributes.titre,
            sousTitre: res.data.data.attributes.sousTitre,
        };

        setDomaineIntroDataStrapi(domaine_intro_datas);
        AOS.refresh();

      })
      .catch(err => {
        console.log('error', err);
      });

    }, []);

    return (
        <>
            
            <div data-aos="fade-up">
                {/* Titre général */}
                <div className="pb-48"></div>
                <div className="flex justify-center w-2/3 m-auto pb-20">
                    <h1 className="font-primary text- uppercase text-center text-3xl sm:text-5xl pb-5 border-b-4 border-primary tracking-widest transition-all ease-in-out duration-500">{domaineIntroDatacStrapi.titre}</h1>
                </div>

                {/* Introduction */}
                <div className="font-primary text-center text-lg sm:text-xl w-2/3 m-auto">
                    <p className="text-primary my-10 mt-0" dangerouslySetInnerHTML={{ __html: domaineIntroDatacStrapi.sousTitre }}></p>
                </div>
            </div>
            <DomainesBlocs/>
            <div className="pb-24"></div>

        </>
    )
}
export default Domaines;